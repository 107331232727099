import React from 'react';
import styled from 'styled-components';

import { DefaultLayout } from '../components/layout';

const Wrapper = styled.div`
  padding: 5em 0;
`;
const Text = styled.p`
  color: rgba(255, 255, 255, .75);
  font-size: 2em;
  text-shadow: 0 0 3px #000;
  text-align: center;
`;

export default () => (
  <DefaultLayout>
    <Wrapper>
      <Text>Page not found.</Text>
    </Wrapper>
  </DefaultLayout>
);